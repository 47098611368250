<template>
  <div class="main_instructions_container">
    <LogoContainer class="mt-2"></LogoContainer>
    <div class="text-container d-flex align-items-center">
      <div>
        <h3 class="title">
          <span class="d-block">Learning Connections</span>
          <span class="d-block">Inventory (LCI)</span>
        </h3>
        <p class="description">
          The LCI is a questionnaire used to measure the degree to which you use
          each of four Learning Patterns.
          <br />Completing the LCI is
          <span class="marks">the 1st step</span> beginning
          the Let Me Learn Process®.
        </p>

        <div>
          <div class="row">
            <div class="col-md-4" v-for="(item, i) in cardItems" :key="i">
              <LciCard :item="item"></LciCard>
            </div>
          </div>
        </div>

        <div class="my-3">
          <button @click="switchToQuestions()" class="btn btn-primary custom-btn rounded-btn lci_btn">Start the LCI</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionsView",
  data() {
    return {
      cardItems: [
        {
          src: require("@/assets/new-theme/icons/clock.png"),
          title: "Take the time you need",
          description:
            "While there are no right or wrong answers, there are answers that are more accurate to who you are than others."
        },
        {
          src: require("@/assets/new-theme/icons/check-circle-broken.png"),
          title: 'Avoid answering "Sometimes"',
          description:
            'Make every attempt to avoid answering "Sometimes" unless the answer truly is "Sometimes".'
        },
        {
          src: require("@/assets/new-theme/icons/target-01.png"),
          title: "Be as accurate and detailed as possible",
          description:
            "Try to be as accurate and detailed as possible in responding to the open-end questions at the end of the questionnaire."
        }
      ]
    };
  },
  methods: {
    switchToQuestions() {
      this.$router.push("/new-theme/questions");
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .main_instructions_container {
    width: 100%;
    height: 100vh;
    position: relative;
    .text-container{
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      .title{
        font-size: 48px;
        font-weight: 600;
      }
      .description{
        font-size: 18px;
      }
      .marks{
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }
}
</style>
